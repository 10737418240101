import React, {useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios'; 
const baseUrl = process.env.REACT_APP_BASE_URL;
const monthlyTotalClients = process.env.REACT_APP_MONTHLY_TOTAL_CLIENT_ENDPOINT;
// const monthlyrevenue = process.env.REACT_APP_MONTHLY_REVENUE_ENDPOINT

const BarChart = () => {
  // {x:"jan",y:12},
  //   {x:"feb",y:30},
  //   {x:"mar",y:25},
  //   {x:"apr",y:22},
  //   {x:"may",y:7},
  //   {x:"jun",y:5},
  //   {x:"jul",y:34},
  //   {x:"aug",y:23},
  //   {x:"sap",y:45},
  //   {x:"oct",y:14},
  //   {x:"nov",y:16},
  //   {x:"dec",y:17},
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        backgroundColor: "#000000"
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#CBD5E0",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: "#4318FF",
              opacity: 1,
            },
            {
              offset: 100,
              color: "red",
              // color: "rgba(67, 24, 255, 1)",
              opacity: 0.28,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "40px",
      },
    },
  });
  
  
  const config = {
    headers: {
        'Content-Type': 'application/json',
    }
};
  useEffect(() => {
    const fetchMonthlyData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/${monthlyTotalClients}`,config);
        const { data } = response;
        setChartData(data.data);
        setChartOptions({
        });
      } catch (error) {
        console.error('Error fetching monthly data:', error);
      }
    };
    fetchMonthlyData()
  }, []); 

  return (
    <Chart
      options={chartOptions}
      series={[{ data: chartData }]}
      type="bar"
      width="100%"
      height="100%"
    />
  );
};

export default BarChart;
