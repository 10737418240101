import React, { useState } from "react";
import Calendar from "react-calendar";
import Card from "components/card";
import EventModal from '../eventModal'; 
import "react-calendar/dist/Calendar.css";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "assets/css/MiniCalendar.css";

const MiniCalendar = () => {
  const [value, onChange] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div> 
      <Card extra="flex w-full h-full flex-col px-3 py-3">
        <Calendar
          onChange={onChange}
          value={value}
          prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
          nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
          view={"month"}
          onClickDay={openModal}
        />
      </Card>

      {/* Event modal */}
      <EventModal
        isOpen={isModalOpen}
        onClose={closeModal}
        selectedDate={value}
      />
    </div>
  );
};

export default MiniCalendar;
