import React from "react";
// Admin Imports
import MainDashboard from "views/admin/default";
import Form from "views/admin/forms"
import Calender from "views/admin/calender-toDo";
import ClientList from "views/admin/clientList";
// import Uploadform from "views/admin/uploadForm";
import Goldprice from  'views/admin/goldprice'
// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import { BiBox } from "react-icons/bi";
import {
  MdHome,
  MdLabel,
  MdDateRange,
  MdPerson,
  MdHotelClass,
  MdLogout
} from "react-icons/md";

const routes = [
  {
    layout: "/",
    icon: <MdHome className="h-6 w-6" />,
    component: <SignIn />,
  },
  
  {
    name: "DASHBOARD",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  
  {
    name: "Form",
    layout: "/admin",
    path: "Form",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Form />,
  },
  {
    name: "To Do",
    layout: "/admin",
    path: "Calender",
    icon: <MdDateRange className="h-6 w-6" />,
    component: <Calender />,
  },
  {
    name: "Gold Live Price",
    layout: "/admin",
    path: "Goldprice",
    icon: <MdHotelClass className="h-6 w-6" />,
    component: <Goldprice />,
  },
  {
    name: "Client listing",
    layout: "/admin",
    path: "ClientList",
    icon: <BiBox className="h-6 w-6" />,
    component: <ClientList />,
  },
  {
    name: "Logout",
    layout: "/auth",
    path: "logout", 
    icon: <MdLogout className="h-6 w-6" />,
    component: <SignIn />
  }
 
];

export default routes;
