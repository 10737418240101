import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import SignIn from 'layouts/auth'
const App = () => {
  return (

    <Routes>
      <Route path="auth/*" element={<SignIn />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="/" element={<Navigate to="/auth" replace />} />
      {/* <Route path="/" element={<Navigate to="/admin/default" replace />} /> */}
    </Routes>

  );
};

export default App;
