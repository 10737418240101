import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import axios from 'axios';
import Swal from 'sweetalert2';

const baseUrl = process.env.REACT_APP_BASE_URL;
const loginEndPoint = process.env.REACT_APP_LOGIN_ENDPOINT;

export default function SignIn() {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setpasswordError] = useState(null);


  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError('email is require!.');
      return;
    }
    if (!password) {
      setpasswordError('password is require!.');
      return;
    }
    if (!validateEmail(email)) {
      setEmailError('Invalid email address.');
      return;
    }
    if (!ValidPassword(password)) {
      setpasswordError('Password should contain 8 characters!');
      return;
    }

    await handleChange()

  }

  const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }
  const ValidPassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  }
  const handleChange = async () => {
    try {
      const data = {
        email: email,
        password: password,
      };
      const result = await axios.post(`${baseUrl}/${loginEndPoint}`, JSON.stringify(data), config);
      if (result.data.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'User already exists',
        });
      } else if (result.data.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Login successful!',
        });
        localStorage.setItem('login_user_id', JSON.stringify({ user_id: result.data.data._id }));
        localStorage.setItem('login_user_email', JSON.stringify({ email: result.data.data.email }));
        localStorage.setItem('login_user_image', JSON.stringify({ image: result.data.data.image }));
        localStorage.setItem('token', result.data.data.token);
        navigate("/admin/default");
      }

    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'User already exists',
        });
      } else {
        console.error("API Error:", error);
        Swal.fire({
          icon: 'error',
          title: 'API Error',
        });
      }
    }
  }


  return (

    <div className="container-fluid vh-100" style={{ background: "#0f1139" }}>
    <div className="row ">
      <div className="col-md-6 col-lg-6 d-none d-sm-block flex-column  justify-content-center align-items-centerr">
        <div className="Login-main-background">
          {/* Background Image */}
        </div>
        <div className="Login-main-content">
          <div className="mt-5 text-center">
            <div className="Login-left-div-small-text">
              <h6>INSPIRED BY THE FUTURE:</h6>
            </div>
            <div className="Login-left-div-big-text">
              <h1>THE VISION UI DASHBOARD</h1>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-md-6 col-lg-6  Login-main  d-none d-sm-block">
        <div className="mt-5">
          <div className="Login-left-div-small-text ">
            <h6>INSPIRED BY THE FUTURE:</h6>
          </div>
          <div className="Login-left-div-big-text">
            <h1>THE VISION UI DASHBOARD</h1>
          </div>
        </div>
      </div> */}
      <div className="col-md-6 col-lg-6 mt-5  d-flex  justify-content-center">

        <form className='mt-5' onSubmit={(e) => handleSubmit(e)}   >

          <h1 className='welcome align-center mt-5'><b>Nice to see you!</b></h1>
          <p style={{ textAlign: "center", fontWeight: "900", color: "white" }}>enter your email and password to login</p>



          <legend className="float-none w-auto mt-5 email-input">Email Id:</legend>
          <div className='icon-input-dv'>
            {/* <MdLockOutline style={{ color: "white", fontSize: "1.5em" }} /> */}
            <input type="text"
              className="login-form-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='example@gmail.com..' />

          </div>


          <legend className="float-none w-auto mt-3 email-input" >Password:</legend>
          <div className='icon-input-dv'>
            {/* <HiOutlineMail style={{ color: "white", fontSize: "1.5em" }} /> */}

            <input type="password"
              className="login-form-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='*********' />
          </div>


          <div className='d-flex align-items-center'></div>
          {/* login error */}
          {emailError && <h2 style={{ color: 'red', fontSize: "15px" }}>{emailError}</h2>}
          {passwordError && <h2 style={{ color: 'red', fontSize: "15px" }}>{passwordError}</h2>}


          <p className='mb-5 forgot-pass'><a href='#'>Forgot your password?</a></p>
          <div className='d-flex jusify-content-center'>
            <input className="btn btn-primary submit-btn"
              type="submit"
              onClick={(e) => handleSubmit(e)}
              value="Login" />
          </div>

          <p style={{ color: "white" }} className="mt-5">Don't have an account?<b>Sign up</b></p>
        </form>
      </div>
    </div>
  </div>


  );
}
