import React, { useState, useEffect } from "react";
import axios from 'axios';
import Calendar from "react-calendar";
import Card from "components/card";
import EventModal from 'components/eventModal';
import "react-calendar/dist/Calendar.css";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "assets/css/MiniCalendar.css";
import { Table, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';


const baseUrl = process.env.REACT_APP_BASE_URL;
const eventListEndPoint = process.env.REACT_APP_CALEDER_FINDEVENT_ENDPOINT;
const deleteEventEndPoint = process.env.REACT_APP_CALEDER_DELETE_EVENT_ENDPOINT
const updateEventEndPoint = process.env.REACT_APP_CALEDER_UPDATE_EVENT_ENDPOINT

const MiniCalendar = () => {
  const [value, onChange] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventlist, setEventlist] = useState([]);
  const [updatedDate, setUpdatedDate] = useState("");
  const [updatedEventDetails, setUpdatedEventDetails] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);

  };

  const deleteEvent = async (eventId) => {
    try {
      const query = { _id: eventId }
      await axios.delete(`${baseUrl}/${deleteEventEndPoint}`, { data: query });
      fetchEvents();
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const handleUpdate = async (eventId,updatedData) => {
    try {
      const updatedData = {
        selectedDate: updatedDate,
        eventDetails: updatedEventDetails
      };
      await axios.patch(`${baseUrl}/${updateEventEndPoint}/${eventId}`, { updatedData });
      fetchEvents();
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${baseUrl}/${eventListEndPoint}`);
      if (response.data.status === 404) {
        console.log('event data null')
      } else {
        setEventlist(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching event list:", error);
    }
  };
  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <div>
      <div style={{ border: '2px solid black', borderRadius: '20px' }}>
        <Card extra="flex w-full h-full flex-col px-3 py-3">
          <Calendar
            onChange={onChange}
            value={value}
            prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
            nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
            view={"month"}
            onClickDay={openModal}
          />
        </Card>

        {/* Event modal */}
        <EventModal
          isOpen={isModalOpen}
          onClose={closeModal}
          selectedDate={value}
          eventData={selectedEvent}
          isUpdate={true}
          onUpdate={handleUpdate}
        />

      </div><div className="mt-5">
        <Table style={{ color: "white" }}>
          {eventlist.map((event, index) => (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>{new Date(event.selectedDate).toLocaleDateString('en-GB')}</Td>
              <Td>{event.eventDetails}</Td>
              <Td>
              <button className="btn btn-danger mx-2" onClick={() => deleteEvent(event._id)}>
                  Delete
                </button>
                {/* <button className="btn btn-success" onClick={() => handleUpdate(event._id)}>
                  Update
                </button> */}
              </Td>
            </Tr>
          ))}
        </Table>
      </div>
    </div>
  );
};

export default MiniCalendar;
