import React, { Component, useEffect, useState } from 'react';
import {
  MdArrowDropUp,
  MdOutlineCalendarToday,
  MdBarChart,
} from "react-icons/md";
import Card from "components/card";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";
import LineChart from "components/charts/LineChart";


import axios from 'axios'; 
const baseUrl = process.env.REACT_APP_BASE_URL;
const monthlyrevenue = process.env.REACT_APP_MONTHLY_REVENUE_ENDPOINT;

const TotalSpent = () => {
  const [chartData, setChartData] = useState([]);
  const [ySum, setYSum] = useState(0);

  const config = {
    headers: {
        'Content-Type': 'application/json',
    }
};

  useEffect(() => {
    const fetchMonthlyData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/${monthlyrevenue}`,config);
        const { data } = response;
        // setChartData(data.data);
        const ySum = data.data.reduce((sum, dataPoint) => sum + dataPoint.y, 0);
        setYSum(ySum)
      } catch (error) {
        console.error('Error fetching monthly data:', error);
      }
    };

    fetchMonthlyData();
  }, []); 
 
  return (
    <Card extra="!p-[20px] text-center WeeklyRevenue">
      <div className="flex justify-between">
      <h2 className="text-lg font-bold text-navy-700 dark:text-white">
      Monthly Revenue
        </h2>
        <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button>
      </div>

      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="flex flex-col">
          <p className="mt-[20px] text-3xl font-bold text-navy-700 dark:text-white">
           ₹{ySum}
           <MdArrowDropUp className="font-medium text-green-500" />
          </p>
          <div className="flex flex-col items-start">
            <p className="mt-2 text-sm text-white">Total Revenue</p> 
          </div>
        </div>
        <div className="h-full w-full">
          <LineChart
            options={lineChartOptionsTotalSpent}
            series={lineChartDataTotalSpent} 
          />
        </div>
      </div>
    </Card>
  );
};

export default TotalSpent;
