import React, { useState,useEffect } from "react";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
// import ClientList from "views/admin/clientList";

const searchEndpoint = process.env.REACT_APP_SEARCH_ENDPOINT
const baseUrl = process.env.REACT_APP_BASE_URL

const Navbar = (props) => {
 
  const { onOpenSidenav, brandText } = props;
  // const searchData = location.searchData ? JSON.parse(decodeURIComponent(location.searchData)) : null;
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();


  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl  p-2 m-2 backdrop-blur-xl bg-[#0b14374d] navbar">
      <div className="ml-[6px]">
        {/* <div className="h-6 w-[224px] pt-1">
        </div> */}

        <p className="shrink text-[33px] capitalize text-white">
        <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white navbar_path"
            href=" "
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
           <Link
            className="text-sm font-normal capitalize  font-bold text-navy-700 hover:underline dark:text-white dark:hover:text-white navbar_path"
            to="#"
          >
            {brandText}
          </Link>
          {/* <span className="mx-1  text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          <Link
            to="#"
            className="font-bold capitalize"
          >
            {brandText}
          </Link> */}
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full px-2 py-2 shadow-xl shadow-shadow-500  shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2 search-input">
        <div className="flex h-full items-center rounded-full text-navy-700 bg-navy-900 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-white" />
          </p>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search..."
            className="block h-full w-full rounded-full  text-sm font-medium text-white outline-none bg-navy-900 placeholder:!text-white sm:w-fit"
          />
        </div>
        
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
       <h2 style={{color:"white"}}> sign up</h2> 
      </div>
    </nav>
  );
};

export default Navbar;
