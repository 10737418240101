import React, { useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
// import Loading from 'assets/img/layout/loading.png'
import Loading from 'assets/img/layout/loadingImage.png';
import Swal from 'sweetalert2';

// import { useNavigate } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;
const kycEndPoint = process.env.REACT_APP_KYC_ENDPOINT;


const Kyc = () => {
    // for kyc
    const [loading, setLoading] = useState(false);
    const [adharCardFront, setAdharCardFront] = useState(null);
    const [adharCardBack, setadharCardBack] = useState(null);
    const [panCard, setpanCard] = useState(null);
    const [panCardError, setpanCardError] = useState("")
    const [adharCardBackError, setadharCardBackError] = useState("")
    const [adharCardFrontError, setadharCardFrontError] = useState("");

    const navigateNextStep = () => {
        var current_fs, next_fs;
        var opacity;

        current_fs = $(".kyc_next").parent();
        next_fs = $(".kyc_next").parent().next();

        $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

        next_fs.show();
        current_fs.animate({ opacity: 0 }, {
            step: function (now) {
                opacity = 1 - now;

                current_fs.css({
                    'display': 'none',
                    'position': 'relative'
                });
                next_fs.css({ 'opacity': opacity });
            },
            duration: 600
        });
    };
    const navigatePreviousStep = () => {
        var current_fs, prev_fs;
        var opacity;

        current_fs = $(".kyc_previous").parent();
        prev_fs = $(".kyc_previous").parent().prev();

        $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

        prev_fs.show();
        current_fs.animate({ opacity: 0 }, {
            step: function (now) {
                opacity = 1 - now;

                current_fs.css({
                    'display': 'none',
                    'position': 'relative'
                });
                prev_fs.css({ 'opacity': opacity });
            },
            duration: 600,
            complete: function () {
            }
        });
    };

    const handleFileChange = (e, setFile) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setFile(file);
            $("#panName").text(file.name);
            $("#aadharName").text(file.name);
            $("#aadhraBackName").text(file.name);
        } else {
            console.log("Invalid file type. Please select an image file.");
        }
    };


    const configForKyc = {
        headers: {
            'Content-Type': 'image/jpeg'
        }
    }
    const validateForm = () => {
        let isValid = true;
        if (!panCard) {
            setpanCardError('panCard is required');
            isValid = false;
        } else {
            setpanCardError('');
        }
        if (!adharCardBack) {
            setadharCardBackError('dharCardBack is required');
            isValid = false;
        } else {
            setadharCardBackError('');
        }
        if (!adharCardFront) {
            setadharCardFrontError('adharCardFront is required');
            isValid = false;
        } else {
            setadharCardFrontError('');
        }

        return isValid;
    };
    const handleKycSubmit = async (e) => {
        if (!validateForm()) {
            e.preventDefault();
            console.log("Form validation failed. Please fill in all required fields.");
        } else {
            setLoading(true);
            await handleKycChange();
            setLoading(false);
        }
    };
    const handleKycChange = async () => {

        const clientDetails = JSON.parse(localStorage.getItem('Client_details'));
        const clientId = clientDetails ? clientDetails._id : null;
        try {
            // for kyc information
            const kycData = new FormData();

            kycData.append('panCard', panCard);
            kycData.append('adharCardFront', adharCardFront);
            kycData.append('adharCardBack', adharCardBack);
            kycData.append('_id', clientId);


            const kycresult = await axios.post(`${baseUrl}/${kycEndPoint}`, kycData, configForKyc);
            if (kycresult.data.status === 400) {
                Swal.fire({
                    icon: 'error',
                    title: 'File size should be less then "700kb"',
                  });
            }
            if (kycresult.data.status === 200) {
                localStorage.setItem('kyc_details', JSON.stringify(kycresult.data.data));
                navigateNextStep()
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                Swal.fire({
                    icon: 'error',
                    title: 'File size should be less then "700kb"',
                  });
            }
        }
    }

    function openCamera() {
        const inputElement = document.getElementById('inputTag');
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                    inputElement.srcObject = stream;
                })
                .catch((error) => {
                    console.error('Error accessing camera:', error);
                });
        } else {
            console.error('Capture attribute is not supported');
        }
    }


    return (
       

        <fieldset>
            <div className="form-card">
                <h2 className="fs-title">kyc Information</h2>

                <div className='form-input'>
                    <label className="inputTag">panCard image:
                        <i className="fa fa-2x fa-camera" onClick={() => openCamera()}></i>
                        <input id="inputTag" type="file" className='forminput-file' capture="environment" name="panCard" accept="image/*" onChange={(e) => handleFileChange(e, setpanCard)} />
                        <div id="imageName">{panCard ? panCard.name : ''}</div>
                        <div className="error-message">{panCardError}</div>
                    </label>
                </div>
               
                <div className='form-input'>
                    <label className="inputTag">AdharCard Front:
                        <i className="fa fa-2x fa-camera" onClick={() => openCamera()}></i>
                        <input id="inputTag" className='forminput-file' type="file" capture="environment" name="adharCardFront" accept="image/*" onChange={(e) => handleFileChange(e, setAdharCardFront)} />
                        <div id="imageName">{adharCardFront ? adharCardFront.name : ''}</div>
                        <div className="error-message">{adharCardFrontError}</div>
                    </label>

                </div>
                <div className='form-input'>
                    <label className="inputTag">AdharCard Back:
                        <i className="fa fa-2x fa-camera" onClick={() => openCamera()}></i>
                        <input id="inputTag" className='forminput-file' type="file" capture="environment" name="adharCardBack" accept="image/*" onChange={(e) => handleFileChange(e, setadharCardBack)} />
                        <div id="imageName">{adharCardBack ? adharCardBack.name : ''}</div>
                        <div className="error-message">{adharCardBackError}</div>

                    </label>
                </div>
            </div>
            <div id="fileError" className="error-message"></div>
            {loading && <img src={Loading} alt="Loading..." className='loading' />}

         

            <input type="button" name="previous" className="kyc_previous action-button-previous" value="Previous" onClick={navigatePreviousStep} />
            <input type="button" name="next" className="kyc_next action-button" value=" Next Step" onClick={(e) => handleKycSubmit(e)} />
        </fieldset>
    )
}


export default Kyc