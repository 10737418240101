import Chart from "react-apexcharts";
import React, { useEffect, useState } from 'react';
import axios from 'axios'; 
const baseUrl = process.env.REACT_APP_BASE_URL;
const monthlyrevenue = process.env.REACT_APP_MONTHLY_REVENUE_ENDPOINT;


const LineChart = (props) => {
  const [chartData, setChartData] = useState([]);
  const {options } = props;
  const config = {
    headers: {
        'Content-Type': 'application/json',
    }
};
  useEffect(() => {
    const fetchMonthlyData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/${monthlyrevenue}`,config);
        const { data } = response;
        setChartData(data.data);
      } catch (error) {
        console.error('Error fetching monthly data:', error);
      }
    };

    fetchMonthlyData();
  }, []); 
 
  return (
    <Chart
      options={options}
      type="line"
      width="100%"
      height="100%"
      series={[{ data: chartData }]}
    />
  );
};

export default LineChart;
