import React, { useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import Loading from 'assets/img/layout/loadingImage.png';
import Swal from 'sweetalert2';

const baseUrl = process.env.REACT_APP_BASE_URL;
const selfEndPoint = process.env.REACT_APP_SELF_ENDPOINT;


const Selfie = () => {
    const [loading, setLoading] = useState(false);
    const [selfie, setSelfie] = useState(null);
    const [ornaments, setOrnaments] = useState([]);
    const [selfieError, setSelfieError] = useState('');
    const [ornamentsError, setOrnamentError] = useState('');



    const handleFileChange = (e, setFile) => {
        const file = e.target.files[0];
        setFile(file);
        $("#selfie").text(file.name);
    };

    // const handleornamentFileChange = (e, setFile) => {
    //     const file = e.target.files;
    //     setFile(file);
    //     const names = Array.from(file).map(file => file.name).join(', ');
    //     $("#ornamentName").text(names);
    // };
    const handleornamentFileChange = (e, setOrnaments) => {
        const files = e.target.files;
        const newOrnaments = [...ornaments]; 
        for (let i = 0; i < files.length; i++) {
            newOrnaments.push(files[i]); 
        }
        setOrnaments(newOrnaments); 
        const names = newOrnaments.map(file => file.name).join(', '); 
        $("#ornamentName").text(names);
    };

    const configForKyc = {
        headers: {
            'Content-Type': 'image/jpeg'
        }
    }
    const navigateNextStep = () => {
        var current_fs, next_fs;
        var opacity;

        current_fs = $(".selfie_next").parent();
        next_fs = $(".selfie_next").parent().next();

        $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

        next_fs.show();
        current_fs.animate({ opacity: 0 }, {
            step: function (now) {
                opacity = 1 - now;

                current_fs.css({
                    'display': 'none',
                    'position': 'relative'
                });
                next_fs.css({ 'opacity': opacity });
            },
            duration: 600
        });
    };
    const navigatePreviousStep = () => {
        var current_fs, prev_fs;
        var opacity;

        current_fs = $(".selfie_previous").parent();
        prev_fs = $(".selfie_previous").parent().prev();

        $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

        prev_fs.show();
        current_fs.animate({ opacity: 0 }, {
            step: function (now) {
                opacity = 1 - now;

                current_fs.css({
                    'display': 'none',
                    'position': 'relative'
                });
                prev_fs.css({ 'opacity': opacity });
            },
            duration: 600,
            complete: function () {
            }
        });
    };
    const validateForm = () => {
        let isValid = true;
        if (!selfie) {
            setSelfieError('selfie is required');
            isValid = false;
        } else {
            setSelfieError('');
        }
        if (!ornaments.length) {
            setOrnamentError('ornaments is required');
            isValid = false;
        } else {
            setOrnamentError('');
        }
        return isValid;
    };

    const handleselfSubmit = async (e) => {
        if (!validateForm()) {
            e.preventDefault();
            console.log("Form validation failed. Please fill in all required fields.");
        } else {
            setLoading(true);
            await handleselfchange();
            setLoading(false);
          
        }
    };

    const handleselfchange = async () => {
        const clientDetails = JSON.parse(localStorage.getItem('Client_details'));
        const clientId = clientDetails ? clientDetails._id : null;
        try {
            //   for self information
            const selfData = new FormData();
            selfData.append('selfie', selfie);
            for (let i = 0; i < ornaments.length; i++) {
                selfData.append(`ornaments`, ornaments[i]);
            }
            selfData.append('_id', clientId);
            const selfresult = await axios.post(`${baseUrl}/${selfEndPoint}`, selfData, configForKyc);
            if (selfresult.data.status === 200) {
                localStorage.setItem('self_details', JSON.stringify(selfresult.data.data));
                navigateNextStep()
            }

        } catch (error) {
            if (error.response && error.response.status === 400) {
                Swal.fire({
                    icon: 'error',
                    title: 'File size should be less then "700kb"',
                  });
            }
        }
    }

    // const openCameraForOrnament = () => {
    //     const inputElement = document.getElementById('ornamentInputTag');
    //     if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //         navigator.mediaDevices.getUserMedia({ video: true })
    //             .then((stream) => {
    //                 inputElement.srcObject = stream;
    //             })
    //             .catch((error) => {
    //                 console.error('Error accessing camera:', error);
    //             });
    //     } else {
    //         console.error('Capture attribute is not supported');
    //     }
    // };

    function openCamera() {
        const inputElement = document.getElementById('inputTag');

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                    inputElement.srcObject = stream;
                })
                .catch((error) => {
                    console.error('Error accessing camera:', error);
                });
        } else {
            console.error('Capture attribute is not supported');
        }
    }

    return (

        <fieldset>
            <div className="form-card">
                <h2 className="fs-title">self Identity</h2>
                <div className='form-input'>
                    <label className="inputTag">Self Image:
                        <i className="fa fa-2x fa-camera" onClick={() => openCamera()}></i>
                        <input id="cameraInputTag" className='forminput-file' type="file" capture="user" accept="image/*" style={{ display: 'none' }} onChange={(e) => handleFileChange(e, setSelfie)} />
                        <input id="inputTag" className='forminput-file' type="file" accept="image/*" onChange={(e) => handleFileChange(e, setSelfie)} />
                        
                        <input id="inputTag" className='forminput-file' type="file" capture="environment" name="selfie" accept="image/*" onChange={(e) => handleFileChange(e, setSelfie)} />
                        <div id="imageName">{selfie ? selfie.name : ''}</div>
                        <div className="error-message">{selfieError}</div>

                    </label>
                </div>
                <div className='form-input'>
                    <label className="inputTag">Ornaments Image:
                        <i className="fa fa-2x fa-camera"></i>
                        <input id="ornamentInputTag" className='forminput-file' type="file" name="ornaments" accept="image/*" onChange={(e) => handleornamentFileChange(e, setOrnaments)} multiple />
                        <div id="ornamentName" style={{color:"green"}}></div>
                        <div className="error-message">{ornamentsError}</div>

                    </label>
                </div>
            </div>
            {loading && <img src={Loading} alt="Loading..." className='loading' />}
            <input type="button" name="previous" className="selfie_previous action-button-previous" value="Previous" onClick={navigatePreviousStep} />
            <input type="button" name="make_payment" className="selfie_next action-button" value="Next Step" onClick={(e) => handleselfSubmit(e)} />
        </fieldset>
    )
}

export default Selfie