import React, { useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import Loading from 'assets/img/layout/loadingImage.png'

const baseUrl = process.env.REACT_APP_BASE_URL;
const bankEndPoint = process.env.REACT_APP_BANK_ENDPOINT;
const chqEndPoint = process.env.REACT_APP_CHQ_ENDPOINT;
const upiEndPoint = process.env.REACT_APP_UPI_ENDPOINT;
const cashEndPoint = process.env.REACT_APP_CASH_ENDPOINT
const paymentTypeEndpoint = process.env.REACT_APP_PAYMENTTYPE_ENDPOINT;



const Bank = ({ setPdfLink,setclientNumber }) => {
    const [loading, setLoading] = useState(false);
    // for bank
    const [accountNo, setAccountNo] = useState('');
    const [bank, setBank] = useState('');
    const [branch, setBranch] = useState('');
    const [IFSC, setIFSC] = useState('');
    const [paymentType, setPaymentType] = useState('')

    // for chq
    const [chequeNo, setChequeNo] = useState('')
    const [upiNo, setUpiNo] = useState('')
    const [payAmount, setPayAmount] = useState('')


    const navigateNextStep = () => {
        var current_fs, next_fs;
        var opacity;

        current_fs = $(".bank_next").parent();
        next_fs = $(".bank_next").parent().next();

        $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

        next_fs.show();
        current_fs.animate({ opacity: 0 }, {
            step: function (now) {
                opacity = 1 - now;

                current_fs.css({
                    'display': 'none',
                    'position': 'relative'
                });
                next_fs.css({ 'opacity': opacity });
            },
            duration: 600
        });
    };
    const navigatePreviousStep = () => {
        var current_fs, prev_fs;
        var opacity;

        current_fs = $(".bank_previous").parent();
        prev_fs = $(".bank_previous").parent().prev();

        $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

        prev_fs.show();
        current_fs.animate({ opacity: 0 }, {
            step: function (now) {
                opacity = 1 - now;

                current_fs.css({
                    'display': 'none',
                    'position': 'relative'
                });
                prev_fs.css({ 'opacity': opacity });
            },
            duration: 600,
            complete: function () {
                // Optional: You can perform any additional actions after the animation completes.
            }
        });
    };
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    };


    const handlecbankSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        handlePaymentTypeChange();
        switch (paymentType) {
            case 'bank':
                await handleBankChange();
                break;
            case 'chq':
                await handleChequeChange();
                break;
            case 'upi':
                await handleUpiChange();
                break;
            case 'cash':
                await handleCashChange();
                break;
            default:
                console.error("Invalid payment type");
                break;
        }
        setLoading(false);
    }

    const clientDetails = JSON.parse(localStorage.getItem('Client_details'));
    const clientId = clientDetails ? clientDetails._id : null;
    const handlePaymentTypeChange = async () => {
        try {
            const data = {
                paymentType: paymentType,
                _id: clientId
            };
            const result = await axios.post(`${baseUrl}/${paymentTypeEndpoint}`, data, config);

            if (result.data.status === 200) {
                localStorage.setItem('payment_details', JSON.stringify(result.data.data));
                        }
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };

    const handleBankChange = async () => {

        try {
            // for bank information
            const data = {
                accountNo: accountNo,
                IFSC: IFSC,
                branch: branch,
                bank: bank,
                // _id: clientId,
                client_id:clientId,
            };

            const Bankresult = await axios.post(`${baseUrl}/${bankEndPoint}`, data, config);
            if(Bankresult.data.status == 404){
                alert("pdf Link not found")
            }
            if (Bankresult.data.status === 200) {
                localStorage.setItem('Bank_details', JSON.stringify(Bankresult.data.data));
                setclientNumber(Bankresult.data.data.CRNno)
                setPdfLink(`https://qgbimages.s3.ap-south-1.amazonaws.com/pdf/${Bankresult.data.pdfsave.pdf}`);
                navigateNextStep();

            }
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };

    const handleChequeChange = async () => {

        try {
            // for cheque information
            const data = {
                chequeNo: chequeNo,
                payAmount: payAmount,
                // _id: clientId,
                client_id:clientId
            };
        
            const Chequeresult = await axios.post(`${baseUrl}/${chqEndPoint}`, data, config);
            if (Chequeresult.data.status === 200) {
                localStorage.setItem('cheque_details', JSON.stringify(Chequeresult.data.data));
                setPdfLink(`https://qgbimages.s3.ap-south-1.amazonaws.com/pdf/${Chequeresult.data.pdfsave.pdf}`);
                navigateNextStep();

            }
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };
    const handleUpiChange = async () => {

        try {
            // for cheque information
            const data = {
                upiNo: upiNo,
                payAmount: payAmount,
                // _id: clientId,
                client_id:clientId
            };
            const result = await axios.post(`${baseUrl}/${upiEndPoint}`, data, config);
            if (result.data.status === 200) {
                localStorage.setItem('cheque_details', JSON.stringify(result.data.data));
                setPdfLink(`https://qgbimages.s3.ap-south-1.amazonaws.com/pdf/${result.data.pdfsave.pdf}`);
                navigateNextStep();

            }
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };
    const handleCashChange = async () => {

        try {
            // for cheque information
            const data = {
                payAmount: payAmount,
                // _id: clientId,
                client_id:clientId
            };
            const result = await axios.post(`${baseUrl}/${cashEndPoint}`, data, config);
            if (result.data.status === 200) {
                localStorage.setItem('cheque_details', JSON.stringify(result.data.data));
                setPdfLink(`https://qgbimages.s3.ap-south-1.amazonaws.com/pdf/${result.data.pdfsave.pdf}`);
                navigateNextStep();

            }
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };
    // const downloadPdf = async (filename) => {
    //     try {
    //         const response = await axios.get(`${baseUrl}/download/${filename}`, {
    //             responseType: 'blob',
    //         });
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', filename || 'your-filename.pdf');
    //         document.body.appendChild(link);
    //         link.click();
    //     } catch (error) {
    //         console.error('Error downloading PDF:', error);
    //     }
    // };

    const renderChequeForm = () => (
        <div className="form-card">
            <h2 className="fs-title">Cheque</h2>
            <input type="text" name="chequeNo" className='form-input' placeholder="Cheque No." value={chequeNo} onChange={(e) => setChequeNo(e.target.value)} />
            <input type="text" name="payAmount" className='form-input' placeholder="Pay Amount" value={payAmount} onChange={(e) => setPayAmount(e.target.value)} />
        </div>
    );

    const renderBankForm = () => (
        <div className="form-card">
            <h2 className="fs-title">Bank</h2>
            <input type="text" name="accountNo" className='form-input' placeholder="Account No." value={accountNo} onChange={(e) => setAccountNo(e.target.value)} />
            <input type="text" name="IFSC" className='form-input' placeholder="IFSC" value={IFSC} onChange={(e) => setIFSC(e.target.value)} />
            <input type="text" name="branch" className='form-input' placeholder="Branch" value={branch} onChange={(e) => setBranch(e.target.value)} />
            <input type="text" name="bank" className='form-input' placeholder="Bank" value={bank} onChange={(e) => setBank(e.target.value)} />
        </div>
    );

    const renderupiForm = () => (
        <div className="form-card">
            <h2 className="fs-title">UPI</h2>
            <input type="text" name="upiNo" className='form-input' placeholder="upi No." value={upiNo} onChange={(e) => setUpiNo(e.target.value)} />
            <input type="text" name="payAmount" className='form-input' placeholder="Pay Amount" value={payAmount} onChange={(e) => setPayAmount(e.target.value)} />
        </div>
    );
    const rendercashForm = () => (
        <div className="form-card">
            <h2 className="fs-title">CASH</h2>
            <input type="text" name="payAmount" className='form-input' placeholder="Pay Amount" value={payAmount} onChange={(e) => setPayAmount(e.target.value)} />
        </div>
    );

    const renderForm = () => {
        switch (paymentType) {
            case 'bank':
                return renderBankForm();
            case 'chq':
                return renderChequeForm();
            case 'upi':
                return renderupiForm();
            case 'cash':
                return rendercashForm();
            default:
                return null;
        }
    };



    return (
        <fieldset>
            <div className='mt-3 d-flex justify-content-center paymentType'>
                <input type="radio" id="cash" name="paymentType" value="cash" onChange={() => setPaymentType('cash')} />
                <label style={{ color: "white" }}> CASH </label>
                <input type="radio" id="bank" name="paymentType" value="bank" onChange={() => setPaymentType('bank')} />
                <label style={{ color: "white" }}> BANK</label>
                <input type="radio" id="chq" name="paymentType" value="chq" onChange={() => setPaymentType('chq')} />
                <label style={{ color: "white" }}> CHQ</label>
                <input type="radio" id="upi" name="paymentType" value="upi" onChange={() => setPaymentType('upi')} />
                <label style={{ color: "white" }}> UPI</label>
            </div>
            <div>
                {renderForm()}
            </div>
            {loading && <img src={Loading} alt="Loading..." className='loading' />}
            <input type="button" name="previous" className="bank_previous action-button-previous" value="Previous" onClick={navigatePreviousStep} />
            <input type="button" name="make_payment" className="bank_next action-button" value="Confirm" onClick={(e) => { handlecbankSubmit(e) }} />
        </fieldset>

    )
}

export default Bank