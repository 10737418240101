import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";

export function SidebarLinks(props) {
  let location = useLocation();

  const { routes, onClose } = props;

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/auth" ||
        route.layout === "/admin" 
      ) {
      
        return (
          
          <Link key={index} to={route.layout + "/" + route.path} onClick={onClose} >
            <div className={`relative  flex hover:cursor-pointer sidenavbar`}>
              <li 
                className="my-[3px] flex cursor-pointer items-center px-8 sidenav-font"
                key={index}
                
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "font-bold text-secondary sidenav-icon "
                      : "font-bold text-white  sidenav-icon"}        `
                }
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 m-1 px-3 flex  ${
                    activeRoute(route.path) === true
                      ? "font-bold text-secondary "
                      : "font-bold text-white"}  `}
                >
                  {route.name}
                </p>
              </li>
              {/* {activeRoute(route.path) ? (
                <div class="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
              ) : null} */}
            </div>
          </Link>
        );
      }
    });
  };
  return createLinks(routes);
}

export default SidebarLinks;
