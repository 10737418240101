import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Modal, Button } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import $ from 'jquery';
import { FiSearch } from "react-icons/fi";

const baseUrl = process.env.REACT_APP_BASE_URL;
const allClientsEndpoint = process.env.REACT_APP_CLIENT_PDF_LIST_ENDPOINT;
const formEndpoint = process.env.REACT_APP_UPLOADFORM_ENDPOINT;
const imagePath = process.env.REACT_APP_GET_IMAGE_PATH_ENDPOINT;
const searchClient = process.env.REACT_APP_SEARCH_ENDPOINT


const ClientList = (props) => {
  const location = useLocation();

  const [clients, setClients] = useState([]);
  const [uploadform, setuploadform] = useState([]);
  const [currentClientId, setCurrentClientId] = useState(null);
  const [showUploadFormModal, setShowUploadFormModal] = useState(false);
  const [showViewImagesModal, setShowViewImagesModal] = useState(false);
  const [clientImages, setClientImages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");



  const [imageName, setImageName] = useState('');
  // const clientDetails = JSON.parse(localStorage.getItem('Client_details'));
  // const clientId = clientDetails ? clientDetails._id : null;


  const handleFileChange = (e, setuploadform) => {
    const files = e.target.files;
    const newimage = [...uploadform];
    for (let i = 0; i < files.length; i++) {
      newimage.push(files[i]);
    }
    setuploadform(newimage);
    const names = newimage.map(file => file.name).join(', ');
    $("#imageName").text(names);
  };

  const config = {
    headers: {
      'Content-Type': 'image/jpeg'
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleChange(currentClientId)
    setShowUploadFormModal(false);
    window.location.reload()
  }

  const handleChange = async (clientId) => {
    try {
      const response = await axios.get(`${baseUrl}/${allClientsEndpoint}`);
      if (response.data.status === 404) {
        return null
      }

      await Promise.all(response.data.data.map(async (client) => {
        const data = new FormData();

        for (let i = 0; i < uploadform.length; i++) {
          data.append('uploadform', uploadform[i]);
        }
        const result = await axios.post(`${baseUrl}/${formEndpoint}?_id=${clientId}`, data, config);
        if (result.data.status === 404) {
          return null;
        }
        if (result.data.status === 200) {
          localStorage.setItem('uploadform_details', JSON.stringify(result.data.data));
          return result.data.data;
        }
      }));

    } catch (error) {
      throw error;
    }
  }

  const handleModalOpen = async (clientId) => {
    setCurrentClientId(clientId);
    const client = clients.find((client) => client._id === clientId);
    
    if (client.uploadform.length > 0) {
      const imagePromises = client.uploadform.map(async (imageName) => {
        try {

          const response = await axios.get(`${baseUrl}/${imagePath}?imageName=${imageName}`, config);
          return response.data.data.link;
        } catch (error) {
          console.log(`Error fetching image ${imageName}:`, error);
          return null;
        }
      });

      const imageLinks = await Promise.all(imagePromises);
      setClientImages(imageLinks.filter(link => link !== null));
      setShowViewImagesModal(true);
    } else {
      setShowUploadFormModal(true);
    }
  };

  const handleModalClose = () => {
    setShowUploadFormModal(false)
  };
  const handleViewImagesModalClose = () => {
    setShowViewImagesModal(false);
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${baseUrl}/${allClientsEndpoint}`);
        if (response.data.status === 404) {
          console.log('client not found')
          return;
        } else {
          setClients(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching client list:", error);
      }
    };

    fetchClients();
  }, []);

  
  // useEffect(() => {
  //   const fetchClientsBySearch = async () => {
  //     try {
       

        
  //       const { client.Name} = searchQuery;

  //       const response = await axios.get(`${baseUrl}/${searchClient}?Name=${searchQuery}`);

  //       if (response.data.status === 200) {
  //         setClients(response.data.data);
  //       } else {
  //         setClients([]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching clients by search:", error);
  //     }
  //   };
  //   if (searchQuery) {
  //     fetchClientsBySearch();
  //   }
  // }, [searchQuery]);


  return (
    <div style={{ color: "white" }}>
      <h1 style={{ fontSize: "50px", textAlign: "center" }}>Client List</h1>
      <div className="flex h-full items-center rounded-full text-navy-700 bg-navy-900 dark:text-white xl:w-[225px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-white" />
        </p>
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="block h-full w-full rounded-full text-sm font-medium text-white outline-none bg-navy-900 placeholder:!text-white sm:w-fit"
        />
      </div>
      {clients.length === 0 ? (
        <p>No clients available.</p>
      ) : (

        <Table className="clientTable">
          <Thead className="tableHead">
            <Tr>
              <Th>No.</Th>
              <Th>CRNno.</Th>
              <Th>Name</Th>
              <Th>Mobile</Th>
              <Th>Location</Th>
              <Th>Upload form</Th>
              <Th>Pdf</Th>
            </Tr>
          </Thead>

          <Tbody>
            {clients.map((client, index) => {

              return (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td className="CRNno">{client.CRNno}</Td>
                  <Td>{client.client_id.Name}</Td>
                  <Td>{client.client_id.Mobile}</Td>
                  <Td>{client.client_id.Location}</Td>
                  <Td>
                    {client.uploadform.length > 0 ? (
                      <Button variant="primary" className="uploadform_btn" onClick={() => handleModalOpen(client._id)}>View</Button>
                    ) : (
                      <Button variant="primary" className="uploadform_btn" onClick={() => handleModalOpen(client._id)}>Upload Form</Button>
                    )}
                  </Td>
                  <Td style={{color:"green"}}>
                    <a href={`https://qgbimages.s3.ap-south-1.amazonaws.com/pdf/${client.pdf}`} target="_blank" rel="noopener noreferrer">
                      Open PDF
                    </a>
                  </Td>
                </Tr>
              )


            })}
          </Tbody>





        </Table>
      )}
      <Modal show={showUploadFormModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form>
            <div className='uploadform-input'>
              <label style={{ marginRight: '20px' }}>Upload Form Image:</label>
              <i className="fa fa-4x fa-camera"></i>
              <input id="inputTag" type="file" name="uploadform" accept="image/*" onChange={(e) => handleFileChange(e, setuploadform)} multiple />
              <div id="imageName">{imageName}</div>
            </div>
            <div className='d-flex justify-content-center mb-5'>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showViewImagesModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {clientImages.map((image, index) => (
            <img key={index} src={image} width="100px" alt={`Image ${index}`} style={{ margin: '5px', display: 'inline-block' }} />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleViewImagesModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ClientList;
