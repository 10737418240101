import React, { useState, useEffect } from "react";
import axios from 'axios';
import Modal from "react-modal";
import { MdClose } from "react-icons/md";

const baseUrl = process.env.REACT_APP_BASE_URL;

const eventEndPoint = process.env.REACT_APP_CALENDER_EVENT_ENDPOINT;

const EventModal = ({ isOpen, onClose, selectedDate }) => {
    const [eventDetails, setEventDetails] = useState("");

    useEffect(() => {
        Modal.setAppElement("#root");
    }, []);

    const handleInputChange = (e) => {
        setEventDetails(e.target.value);

    };

    const handleAddEvent = () => {
        handleChange()
        console.log(`Event added on ${selectedDate}: ${eventDetails}`);
        setEventDetails("");
        onClose();

    };
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const handleChange = async () => {
        try {

            const parsedDate = new Date(selectedDate); 
            const formattedDate = parsedDate.toLocaleDateString('en-GB');

            const data = {
                selectedDate: formattedDate,
                eventDetails: eventDetails
            }
            const result = await axios.post(`${baseUrl}/${eventEndPoint}`, data, config);
            if (result.data.status === 200) {
                localStorage.setItem('Event_details', JSON.stringify(result.data.data));
            }
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Event Modal"
            className="EventModal"
        >
            <div className="EventModal-header">
                <h2>Add Event on {selectedDate.toDateString()}</h2>
                <button className="EventModal-close" onClick={onClose}>
                    <MdClose />
                </button>
            </div>
            <label>
                Event Details:
                <input
                    type="text"
                    value={eventDetails}
                    onChange={handleInputChange}
                />
            </label>
            <button onClick={handleAddEvent}>Add Event</button>
        </Modal>
    );
};

export default EventModal;
