import { MdDashboard, MdPerson } from "react-icons/md";
import { useState, useEffect } from 'react';
import { IoIosPeople } from "react-icons/io";
import { AiFillGold } from "react-icons/ai";
import Widget from "components/widget/Widget";
import Widget1 from "components/widget/widget1";
import Widget2 from "components/widget/widget2";
import Widget3 from "components/widget/widget3";
import Widget4 from "components/widget/widget4";
import Widget5 from "components/widget/widget5";
import Calender from 'components/calendar/MiniCalendar'
import TotalClient from './totalClientgraph'
import TotalSpent from './revenue'
// import Form from "../forms/index";
import axios from 'axios'


const baseUrl = process.env.REACT_APP_BASE_URL;
const goldEndPoint = process.env.REACT_APP_GOLD_ENDPOINT;
const allClientsEndpoint = process.env.REACT_APP_GETALLCLIENT_ENDPOINT;


const Dashboard = () => {
  const [goldPrice, setGoldPrice] = useState(null);
  const [clients, setClients] = useState([]);


  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data={
          carat:24,
          gram:10
        }
        const Goldresult = await axios.post(`${baseUrl}/${goldEndPoint}`,data, config);
        if (Goldresult.data.status === null) {
          console.log('not found data')
        }
        if (Goldresult.data.status === 200) {
          setGoldPrice(Goldresult.data.data);
        }

        const response = await axios.get(`${baseUrl}/${allClientsEndpoint}`);
        if (Goldresult.data.status === null) {
          console.log('not found data')
        }else{
          setClients(response.data.data);
        }
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container">

      <div className="mt-10 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-3">

        <Widget1
          icon={<MdPerson className="h-7 w-7 " />}
          title={"New Client"}
        />

        <Widget2
          icon={<IoIosPeople className="h-6 w-6 " />}
          title={"Existing Client"}
          subtitle={clients.length}
        />
        {goldPrice !== null ? (
          <Widget3
            icon={<AiFillGold className="h-7 w-7 " />}
            title={"Today gold price"}
            subtitle={`24k-₹${goldPrice.result}`}
          />
        ) : (
          <p>Loading gold price...</p>
        )}

        <Widget4
          icon={<AiFillGold className="h-7 w-7 " />}
          title={"Report"}
        // subtitle={"50,000+"}
        className='widget4'
        />

        <Widget5
          icon={<MdDashboard className="h-6 w-6" />}
          title={"check kyc"}
        // subtitle={"10,000+"}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"check client signed form"}
          // subtitle={"100+"}
        />

        <Calender />
        <div className="col-span-1 md:col-span-1 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
          <TotalClient />
        </div>
        <div className="col-span-1 md:col-span-1 lg:col-span-full xl:col-span-full 2xl:col-span-full">
        <TotalSpent/>
        </div>
       
      </div>
    </div>
  );
};

export default Dashboard;
