import React, { useState, useEffect } from "react";
import axios from 'axios';
// import { useNavigate } from "react-router-dom";
import Client from './client.jsx'
import Kyc from './kyc.jsx'
import Selfie from './selfie.jsx';
import Goldvalue from './goldValue.jsx';
import Bank from './bank.jsx'

const baseUrl = process.env.REACT_APP_BASE_URL;
const allClientsEndpoint = process.env.REACT_APP_GETALLCLIENT_ENDPOINT;
const pdfEndPoint = process.env.REACT_APP_PRINT_ENDPOINT

const Form = () => {
    const [clients, setClients] = useState([]);
    const [pdfLink, setPdfLink] = useState('');
    // const [newClient, setNewClient] = useState(null);
    const [clientNumber,setclientNumber] = useState('')

    const clientDetails = JSON.parse(localStorage.getItem('Client_details'));
    const clientId = clientDetails ? clientDetails._id : null;
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    };

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get(`${baseUrl}/${allClientsEndpoint}`, config);
                if (response.data.status === 200) {
                    setClients(response.data.data[0]);
                }

            } catch (error) {
                console.error("Error fetching client list:", error);
            }
        };

        fetchClients();
    }, []);

  

    const DownloadButton = ({ pdfLink }) => {
        return (
            <div className="d-flex justify-content-center">
                {pdfLink && (
                    <a href={`${pdfLink}`} className="btn btn-primary" target='_blank' download={pdfLink}>
                          View and Download PDF
                    </a>
                )}
            </div>
        );
    };

    return (

        <div className="container-fluid mt-5" id="grad1">
            <div className="row justify-content-center mt-0">
                <div className="col-11 col-sm-9 col-md-7 col-lg-6 text-center p-0 mt-3 mb-2">
                    <div className="card px-0 pt-4 pb-0 mt-3 mb-3 form">
                        <h1 className='form-main-title'><strong>Customer details</strong></h1>
                        <p className='form-main-title'>Fill all form field to go to next step</p>
                        <div className="row">
                            <div className="col-md-12 mx-0">
                                <form id="msform" method="post" encType="multipart/form-data">

                                    <ul id="progressbar" className='d-flex justify-content-center'>
                                        <li className="active" id="account"><strong>Client Information</strong></li>
                                        <li id="kyc"><strong>kyc</strong></li>
                                        <li id="self"><strong>Self Identity</strong></li>
                                        <li id="gold"><strong>gold </strong></li>
                                        <li id="bank"><strong> bank</strong></li>
                                        <li id="confirm"><strong> Success</strong></li>
                                    </ul>
                                    <Client/>
                                    <Kyc />
                                    <Selfie />
                                    <Goldvalue />
                                    <Bank setPdfLink={setPdfLink} setclientNumber={setclientNumber} />
                                    <fieldset>
                                        <div className="form-card">
                                            <h2 className="fs-title text-center">Success !</h2>
                                            <br /><br />
                                            <div className="row justify-content-center">
                                                <div className="col-3">
                                                    <img src="https://img.icons8.com/color/96/000000/ok--v2.png" className="fit-image" />
                                                </div>

                                            </div>

                                            <div className="row justify-content-center">
                                                <div className="text-center " style={{ fontSize: '50px' }}>
                                                    {/* {newClient ? newClient.CRNno : ''} */}
                                                    {clientNumber}
                                                </div>
                                                <DownloadButton pdfLink={pdfLink} />
                                            </div>
                                            <br /><br />
                                            <div className="row justify-content-center">
                                                <div className="col-7 text-center">
                                                    <h5>You Have Successfully client register</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Form