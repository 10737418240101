import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';
// import { useNavigate } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;
const clientEndPoint = process.env.REACT_APP_CLIENT_ENDPOINT;


const Client = () => {
    // const navigate = useNavigate();

    // for client
    const [Name, setName] = useState('');
    const [Mobile, setMobile] = useState('');
    const [Location, setLocation] = useState('');
    const [nameError, setNameError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [samemobileError, setsameMobileError] = useState('');
    const [locationError, setLocationError] = useState('');

    const handleMobileKeyPress = (e) => {
        // Check if the current value length is already 10
        if (Mobile.trim().length === 10) {
            e.preventDefault();
        }
    };


    const navigateNextStep = () => {
        var current_fs, next_fs;
        var opacity;

        current_fs = $(".client_next").parent();
        next_fs = $(".client_next").parent().next();

        $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

        next_fs.show();
        current_fs.animate({ opacity: 0 }, {
            step: function (now) {
                opacity = 1 - now;

                current_fs.css({
                    'display': 'none',
                    'position': 'relative'
                });
                next_fs.css({ 'opacity': opacity });
            },
            duration: 600
        });
    };


    const validateForm = () => {
        let isValid = true;

        // Validate Name
        if (!Name.trim()) {
            setNameError('Name is required');
            isValid = false;
        } else {
            setNameError('');
        }

        // Validate Mobile
        if (!Mobile.trim()) {
            setMobileError('Mobile is required');
            isValid = false;
        } else {
            setMobileError('');
        }

        // Validate Location
        if (!Location.trim()) {
            setLocationError('Location is required');
            isValid = false;
        } else {
            setLocationError('');
        }

        return isValid;
    };

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const handleClientSubmit = async (e) => {
        if (!validateForm()) {
            e.preventDefault();
            console.log("Form validation failed. Please fill in all required fields.");
        } else {
            await handleclientChange();
        }

    };

   
        const fetchClientDetails = async () => {
            if (Mobile) {
                try {
                    const response = await axios.get(`${baseUrl}/client/${Mobile}`);
                    if (response.data.status === 200) {
                        const Name = response.data.data.Name;
                        const Location = response.data.data.Location
                        setName(Name);
                        setLocation(Location);
                    } else {
                        setName('');
                        setLocation('');
                    }
                } catch (error) {
                    console.error("Error fetching client details:", error);
                }
            }
        };
        // fetchClientDetails();
   

    const handleclientChange = async () => {

        try {
            const data = {
                Name: Name,
                Mobile: Mobile,
                Location: Location
            };
            const Clientresult = await axios.post(`${baseUrl}/${clientEndPoint}`, data, config);
            if (Clientresult.data.status === 200) {
                localStorage.setItem('Client_details', JSON.stringify(Clientresult.data.data));
                navigateNextStep();
            }
        } catch (error) {

            Swal.fire({
                icon: 'error',
                title: 'Unexpected error submitting client data',
            });

        }
    };

    return (



        <fieldset>
            <div className="form-card">
                <h2 className="fs-title">Client Information</h2>
                <input type="text" name="name" className='form-input' placeholder="Name" value={Name} onChange={(e) => setName(e.target.value)} />
                <div className="error-message">{nameError}</div>
                <input type="text" name="Mobile" className='form-input' placeholder="Contact No." onKeyPress={handleMobileKeyPress} value={Mobile}
                    onChange={(e) => { setMobile(e.target.value); fetchClientDetails(); }}

                />
                <div className="error-message">{mobileError}{samemobileError}</div>
                <input type="text" name="location" className='form-input' placeholder="Location" value={Location} onChange={(e) => setLocation(e.target.value)} />
                <div className="error-message">{locationError}</div>
            </div>
            <input type="button" name="next" className="client_next action-button" onClick={(e) => handleClientSubmit(e)} value="Next Step" />
        </fieldset>



    )
}

export default Client