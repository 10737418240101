/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import FinalLogo from 'assets/img/dashboards/white-removebg-preview.png'

import routes from "routes.js";

const Sidebar = ({ open, onClose }) => {
  
  
  return (
    <div
      className={`sm:none sidenav duration-175 mt-3  fixed !z-50 flex  flex-col  pb-5 shadow-2xl shadow-white/5 transition-all text-white  md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className="mb-5 flex items-center justify-center" >
        
    <div className="mb-5 h-2.5 text-[26px] font-bold text-white">
        <img src={FinalLogo} style={{width: "175px" }} className="logoimg" alt="Logo" />
    </div>
</div>
      <div className="mt-[41px] mb-1 h-px bg-white/30 hr-sidenav"  />
      <ul className="mb-auto overflow-y-visible   pt-1 " >
        <Links routes={routes}  />
      </ul>
      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
 