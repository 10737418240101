import React, { useEffect, useState } from "react";
import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL;
const goldEndPoint = process.env.REACT_APP_GOLD_ENDPOINT;

const Goldprice = () => {
    const [gold24Price, setGold24Price] = useState(null);
    const [gold22Price, setGold22Price] = useState(null);
    const [gold20Price, setGold20Price] = useState(null);
    const [gold18Price, setGold18Price] = useState(null);


    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = {
                    carat: 24,
                    gram: 10
                }
                const Gold24result = await axios.post(`${baseUrl}/${goldEndPoint}`, data, config);
                if (Gold24result.data.status === null) {
                    console.log('not found data')
                }
                if (Gold24result.data.status === 200) {
                    setGold24Price(Gold24result.data.data);
                }

                const data1 = {
                    carat: 22,
                    gram: 10
                }
                const Gold22result = await axios.post(`${baseUrl}/${goldEndPoint}`, data1, config);
                if (Gold22result.data.status === null) {
                    console.log('not found data')
                }
                if (Gold22result.data.status === 200) {
                    setGold22Price(Gold22result.data.data);
                }

                const data2 = {
                    carat: 20,
                    gram: 10
                }
                const Gold20result = await axios.post(`${baseUrl}/${goldEndPoint}`, data2, config);
                if (Gold20result.data.status === null) {
                    console.log('not found data')
                }
                if (Gold20result.data.status === 200) {
                    setGold20Price(Gold20result.data.data);
                }

                const data3 = {
                    carat: 18,
                    gram: 10
                }
                const Gold18result = await axios.post(`${baseUrl}/${goldEndPoint}`, data3, config);
                if (Gold18result.data.status === null) {
                    console.log('not found data')
                }
                if (Gold18result.data.status === 200) {
                    setGold18Price(Gold18result.data.data);
                }


            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);
    return (
        <div className="container mt-5">
            <h1 className="goldPriceHead"> Today Gold Live Buying Price</h1>
            <div className="row d-flex justify-content-center m-5">
                <div className="  liveprice-gold">
                    <div className="card mt-5 carat-main" >
                        {gold24Price !== null ? (
                            <div className="row">
                                <div className="col-3 carat">24k</div>
                                <div className="col-9">

                                    {`₹${gold24Price.result}`}
                                </div>
                            </div>
                        ) : (
                            <p>Loading gold price...</p>
                        )}
                    </div>
                </div>
                
                <div className=" liveprice-gold">
                    <div className="card mt-5 carat-main">
                        {gold22Price !== null ? (
                            <div className="row">
                                <div className="col-3 carat">22k  </div>
                                <div className="col-9">
                                    {` ₹${gold22Price.result}`}
                                </div>
                            </div>

                        ) : (
                            <p>Loading gold price...</p>
                        )}
                    </div>
                </div> 
                
                <div className=" liveprice-gold">
                    <div className="card mt-5 carat-main" >
                        {gold20Price !== null ? (
                             <div className="row">
                             <div className="col-3 carat">20k  </div>
                             <div className="col-9">
                             {`₹${gold20Price.result}`}
                             </div>
                         </div>
                        ) : (
                            <p>Loading gold price...</p>
                        )}
                    </div>
                </div>
                <div className=" liveprice-gold">
                    <div className="card mt-5 carat-main">
                        {gold18Price !== null ? (
                             <div className="row">
                             <div className="col-3 carat">18k  </div>
                             <div className="col-9">
                             {`₹${gold18Price.result}`}
                             </div>
                         </div>
                        ) : (
                            <p>Loading gold price...</p>
                        )}
                    </div>
                </div>
            </div>

            {/* <div className="row d-flex justify-content-center">
                <div className="col-sm-6 col-md-6 liveprice-gold">
                    <div className="card mt-5 carat-main" >
                        {gold20Price !== null ? (
                             <div className="row">
                             <div className="col-3 carat">20k  </div>
                             <div className="col-9">
                             {`₹${gold20Price.result}`}
                             </div>
                         </div>
                        ) : (
                            <p>Loading gold price...</p>
                        )}
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 liveprice-gold">
                    <div className="card mt-5 carat-main">
                        {gold18Price !== null ? (
                             <div className="row">
                             <div className="col-3 carat">18k  </div>
                             <div className="col-9">
                             {`₹${gold18Price.result}`}
                             </div>
                         </div>
                        ) : (
                            <p>Loading gold price...</p>
                        )}
                    </div>
                </div>
            </div> */}
        </div>
    );

}

export default Goldprice