import React, { useState, useEffect } from 'react';
import { MdAddBox, MdDelete } from "react-icons/md";
import axios from 'axios';
import $ from 'jquery';


const baseUrl = process.env.REACT_APP_BASE_URL;
const goldEndPoint = process.env.REACT_APP_ADD_GRAM_AND_CARAT_ENDPOINT;
const goldDataFetchEndPoint = process.env.REACT_APP_ADD_PRICE_ENDPOINT;
const deleteEndpoint = process.env.REACT_APP_DELETE_GRAM_AND_CARAT_ENDPOINT;
const paymentTypeEndpoint = process.env.REACT_APP_PAYMENTTYPE_ENDPOINT;

const Goldvalue = () => {
    const [goldDetails, setGoldDetails] = useState([{ carat: '', gram: '', price: 0 }]);
    const [totalPrices, setTotalPrices] = useState(0);
    const [formSubmitted, setFormSubmitted] = useState(false);


    const addRow = () => {
        setGoldDetails(prevGoldDetails => [
            ...prevGoldDetails,
            { carat: '', gram: '', price: 0 }
        ]);
    };
    const deleteRow = async (index) => {
        const clientDetails = JSON.parse(localStorage.getItem('Client_details'));
        const clientId = clientDetails ? clientDetails._id : null;
        if (goldDetails.length === 1) {
            return;
        }

        const updatedGoldDetails = [...goldDetails];
        const deletedRowPrice = updatedGoldDetails[index].price;
        updatedGoldDetails.splice(index, 1);
        setGoldDetails(updatedGoldDetails);
        try {
            const response = await axios.delete(`${baseUrl}/${deleteEndpoint}`, { data: { index, clientId } });
            if (response.status === 200) {
                const newTotalPrice = totalPrices - deletedRowPrice;
                setTotalPrices(newTotalPrice);
            }
        } catch (error) {
            console.error('Error deleting row:', error);
        }

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await savedata();
        setFormSubmitted(true);
        navigateNextStep()

    }


    const navigateNextStep = () => {
        var current_fs, next_fs;
        var opacity;

        current_fs = $(".gold_next").parent();
        next_fs = $(".gold_next").parent().next();

        $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

        next_fs.show();
        current_fs.animate({ opacity: 0 }, {
            step: function (now) {
                opacity = 1 - now;

                current_fs.css({
                    'display': 'none',
                    'position': 'relative'
                });
                next_fs.css({ 'opacity': opacity });
            },
            duration: 600
        });
    };
    const navigatePreviousStep = () => {

        var current_fs, prev_fs;
        var opacity;

        current_fs = $(".gold_previous").parent();
        prev_fs = $(".gold_previous").parent().prev();

        $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

        prev_fs.show();
        current_fs.animate({ opacity: 0 }, {
            step: function (now) {
                opacity = 1 - now;

                current_fs.css({
                    'display': 'none',
                    'position': 'relative'
                });
                prev_fs.css({ 'opacity': opacity });
            },
            duration: 600,
            complete: function () {
                // Optional: You can perform any additional actions after the animation completes.
            }
        });
    };

    const handleChange = async (value, rowIndex, field) => {
        const numericValue = parseFloat(value);
        const updatedGoldDetails = [...goldDetails];

        updatedGoldDetails[rowIndex][field] = value;
        setGoldDetails(updatedGoldDetails);
        fetchData(goldDetails[rowIndex].carat, goldDetails[rowIndex].gram, rowIndex);

    };


    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const savedata = async (carat, gram, rowIndex) => {
        const clientDetails = JSON.parse(localStorage.getItem('Client_details'));
        const clientId = clientDetails ? clientDetails._id : null;

        try {
        
            const deletePastData = await axios.delete(`${baseUrl}/${deleteEndpoint}`, { data: {client_id: clientId } });

           if(deletePastData.status == 200){
            console.log(deletePastData.data.data)
           }
            const data = {
                jewelryDataArray: goldDetails.map(({ carat, gram }) => ({ carat, gram })),
                client_id: clientId
            };

            const Goldresult = await axios.post(`${baseUrl}/${goldEndPoint}`, data, config);
            if (Goldresult.data.status == 200) {
                const updatedGoldDetails = [...goldDetails];
                Goldresult.data.data.forEach((price, index) => {
                    updatedGoldDetails[index].price = price;
                });
                setGoldDetails(updatedGoldDetails);
                calculateTotalPrices(updatedGoldDetails);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    const fetchData = async (carat, gram, rowIndex) => {
        const clientDetails = JSON.parse(localStorage.getItem('Client_details'));
        const clientId = clientDetails ? clientDetails._id : null;
        try {

            const data = {
                carat,
                gram,
                client_id: clientId
            };
            const Goldresult = await axios.post(`${baseUrl}/${goldDataFetchEndPoint}`, data, config);
            if (Goldresult.data.status == 200) {
                const updatedGoldDetails = [...goldDetails];
                updatedGoldDetails[rowIndex].price = Goldresult.data.data.price;
                setGoldDetails(updatedGoldDetails);
                calculateTotalPrices(updatedGoldDetails);
            }


        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const calculateTotalPrices = (goldDetails) => {
        const total = goldDetails.reduce((sum, item) => sum + item.price, 0);
        const roundedTotal = total.toFixed(2);
        setTotalPrices(roundedTotal);
    };

    useEffect(() => {
    }, []);


    return (
        <fieldset>
            <div className="form-card">
                <h2 className="fs-title">Gold Value</h2>
                <table>

                    <tr>
                        <th>Carat</th>
                        <th>Gram</th>
                        <th>Price</th>
                        <th>Add /delete </th>
                    </tr>
                    {goldDetails.map((row, index) => (
                        <tr key={row.id}>
                            <td>
                                <select
                                    id={`caratSelect-${index}`}
                                    name={`carat-${index}`}
                                    value={row.carat}
                                    onChange={(e) => handleChange(e.target.value, index, 'carat')}
                                >
                                    <option disabled>Select Carat</option>
                                    <option value="24">24</option>
                                    <option value="22">22</option>
                                    <option value="20">20</option>
                                    <option value="18">18</option>
                                </select>
                            </td>
                            <td>
                                <input
                                    type="number"
                                    className=""
                                    value={row.gram}
                                    onChange={(e) => handleChange(e.target.value, index, 'gram')}
                                />
                                <br />
                            </td>
                            <td><p>{row.price}</p></td>
                            <td>
                                <button type="button" onClick={() => deleteRow(index)}>
                                    <MdDelete style={{ 'fontSize': '25px', color: 'red' }} />
                                </button>
                                <button type="button" onClick={addRow}>
                                    <MdAddBox style={{ 'fontSize': '25px', color: 'green' }} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </table>
                <h1 className='mt-3' style={{ fontSize: '20px' }}>Total Prices: {totalPrices}</h1>
            </div>

            <input type="button" name="previous" className="gold_previous action-button-previous" value="Previous" onClick={navigatePreviousStep} />
            <input type="button" name="make_payment" className="gold_next action-button" value="Next Step" onClick={(e) => { handleSubmit(e) }} />
        </fieldset>
    );
};

export default Goldvalue;



