const Footer = () => {
  return (
    <div className="flex w-full items-center pb-8 pt-3 lg:px-8 xl:flex-row">
      <p className="mx-auto mb-4 text-center text-sm text-white sm:mb-0 md:text-base">
        ©{1900 + new Date().getYear()} Quick gold buyer All Rights Reserved.
      </p>
    </div>
  );
};

export default Footer;
